export default [
  "accessory-case",
  "action-menu",
  "accessory-windshield",
  "appointment-fill",
  "arrow-down",
  "arrow-dx",
  "arrow-small-down",
  "arrow-small-dx",
  "arrow-small-sx",
  "arrow-small-up",
  "arrow-sx",
  "arrow-up",
  "assistance",
  "bell-fill",
  "brand",
  "brochure-fill",
  "brochure",
  "brochure-vc",
  "cabina-vc",
  "calculator-vc",
  "capacity-vc",
  "calculator-fill",
  "calculator",
  "calendar",
  "certification-fill",
  "certification",
  "certification-vc",
  "certified-assistance",
  "certified-assistance-small",
  "checkup",
  "check",
  "close",
  "compact-vc",
  "configuration-vc",
  "contact-fill",
  "contract-fill",
  "directions",
  "divider",
  "eco-vc",
  "edit-fill",
  "edit",
  "editdocument-fill",
  "electric",
  "email-fill",
  "email",
  "engine-vc",
  "euro-vc",
  "external",
  "file-upload",
  "filters",
  "filter-vc",
  "finance-fill",
  "flexibility",
  "garbage",
  "gears-fill",
  "gears",
  "geolocation",
  "gift-fill",
  "globe-fill",
  "globe",
  "globe-vc",
  "gps-off",
  "gps-on",
  "heart-empty",
  "heart-fill",
  "helmet-fill",
  "helmet",
  "hours-fill",
  "hours",
  "info-fill",
  "info",
  "insurance-fill",
  "insurance",
  "list",
  "lounge-fill",
  "lounge",
  "mail-vc",
  "map-list",
  "map",
  "megaphone-fill",
  "megaphone",
  "menu",
  "merchandising-fill",
  "merchandising",
  "mobile-fill",
  "mobile",
  "motorbike",
  "mute",
  "paint-fill",
  "paint",
  "passi-vc",
  "pause",
  "phone",
  "phone-vc",
  "piaggioflat",
  "pin-fill",
  "pin",
  "pin-vc",
  "play",
  "plus-fill",
  "plus",
  "print-fill",
  "print",
  "promo-fill",
  "promo",
  "purchase",
  "read-more",
  "save-fill",
  "save",
  "scooter",
  "search-fill",
  "search",
  "sentmail-fill",
  "share-fill",
  "social-fb",
  "social-instagram",
  "social-tiktok",
  "social-x",
  "social-twitter",
  "social-whatsapp-fill",
  "social-whatsapp",
  "social-youtube",
  "sorting",
  "spart-parts",
  "staff-fill",
  "swatch-active",
  "swatch",
  "tachimeter-fill",
  "tachimeter",
  "tick",
  "tire",
  "tow",
  "unmute",
  "unmute-vc",
  "user-fill",
  "user",
  "user-vc",
  "warning-fill",
  "warning",
  "wash-fill",
  "wash",
  "wifi",
  "quote",
  "capacity",
  "compact",
  "eco",
  "engine",
  "filter",
  "recharge",
  "steeringWheel",
  "technology",
  "technology-2",
  "vespa_pin"
];
