<template>
  <div id="navigation-v2"
       class="navigation-v2"
       :aria-label="secondaryAriaLabel"
       ref="allFocusable">
    <div :class="['navigation-v2__content-wrapper', { 'overlay-active': currentActive },{ 'margin-edit-mode': isEditInterface }]"
         :style="overlayPosition"
    >
      <div class="navigation-v2__content">
          <ul class="navigation-v2__menu" aria-label="Main navigation">
          <li
              v-for="(item, index) in menuItemsObj"
              :class="'navigation-v2__menu__list'"
              v-bind:key="item.uuid"
          >
            <pg-button
                :class="['navigation-v2__menu__list__item', { 'active' : activeElement(item.uuid, index)}]"
                :aria-expanded="activeElement(item.uuid, index).toString()"
                event-name="expandNested"
                :event-params="{ uuid: item.uuid }"
                :aria-controls="'nav-submenu-' + item.uuid"
                :analytics-data="analyticsData(item)"
                v-if="item.nestedItems || item['menu-tabs-area']"
            >
              <span class="navigation-v2__menu__list__item__voice"> {{ item.label }} </span>
              <i :class="navigationListIcon" aria-hidden="true"></i>
            </pg-button>
            <pg-button
                :class="'navigation-v2__menu__item'"
                :href="item.url | mgnlLink('url')"
                :targetBlank="!!item.onBlank"
                :analytics-data="analyticsData(item)"
                v-else
            >
            <span class="navigation-v2__menu__list__item__voice"> {{ item.label }} </span>
            </pg-button>
          </li>
        </ul>
      </div>
      <template v-if="!isEditInterface">
        <main-navigation-item-v2
            v-for="(item, index) in menuItemsObj"
            :key="item.uuid"
            :item="item"
            :index="index"
            :highlightItems="item.highlightItems"
            :isActiveElement="activeElement(item.uuid, index)"
            :posterImage="item.posterImage"
        />
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </div>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import MainNavigationItemV2 from "./MainNavigationItemV2.vue";
export default {
  name: "MainNavigationV2",

  components: {
    PgButton,
    MainNavigationItemV2
  },

  data() {
    return {
      visible: false,
      currentActive: null,
      isNavigationOpen: true,
      overlayPosition: '',
    };
  },

  props: {
    common: {
      type: Object,
    },
    customerServiceLink: {
      type: Object,
      default: () => {}
    },
    services: {
      type: Array,
      default: () => []
    },
    menuItemsObj: {
      type: Array,
      default: () => [],
    },
    highlightItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    posterImage: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEditInterface: {
      type: Boolean,
      default: false,
    },
    hideNavigation: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    activeElement() {
      const ctx = this;
      return function (uuid, index) {
        return  ctx.currentActive === uuid;
      };
    },

    isMobile() {
      return window.screen.width <= 1024;
    },
    secondaryAriaLabel() {
      return this.$msg('common.secondary.aria-label.text') //TODO add fallback in case msg not configured
    },
    navigationListIcon() {
      return this.isMobile ? 'icon-arrow-dx' : 'icon-arrow-down';
    }
  },

  methods: {
    isHidden(el) {
      if (
          window.getComputedStyle(el).visibility === "hidden" ||
          el.offsetParent === null
      ) {
        return true;
      }
    },
    handleKeyboard(event) {
      // A11Y KEYBOARD ESC + MODAL TRAP

      const allFocusableList = this.$refs.allFocusable.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      let allFocusableListVisible = [];

      allFocusableList.forEach((element) => {
        if (!this.isHidden(element)) {
          allFocusableListVisible.push(element);
        }
      });

      const last = allFocusableListVisible.length - 1;

      if (event.key === "Escape") {
        if (this.visible) {
          this.$eventHub.emit("header:toggleNavigation");
        }
      }

      if (
          event.key === "Tab" &&
          event.shiftKey === false &&
          event.target === allFocusableListVisible[last]
      ) {
        event.preventDefault();
        allFocusableListVisible[0].focus();
      } else if (
          event.key === "Tab" &&
          event.shiftKey === true &&
          event.target === allFocusableListVisible[0]
      ) {
        event.preventDefault();
        allFocusableListVisible[last].focus();
      }
    },
    analyticsData(el) {
      return {
        evCategory: 'menu',
        evAction: 'menu_principale',
        evLabel: el.label.trim()
      }
    },
    overlayPositioning() {
      //calculate the left % when the window is > 1920px
      const currentViewport = window.innerWidth;
      const defLeft = 0;
      if(currentViewport <= 1920) {
        this.overlayPosition = `--float-overlay: ${defLeft}px`
      } else {
        let calcFloat = ((currentViewport - 1920 ) / 2);
        this.overlayPosition = `--float-overlay: ${calcFloat}px`
      }
    },
  },

  created() {
    this.overlayPositioning();
    document.addEventListener("keydown", this.handleKeyboard);
    const ctx = this;

    this.$eventHub.on("header:toggleNavigation", function () {
      const bodyEl = document.body;
      const inertEls = document.querySelectorAll("#mainContent, #footer");

      ctx.visible = !ctx.visible;

      ctx.visible
          ? bodyEl.classList.add("hidden-scroll", "main-navigation-open")
          : bodyEl.classList.remove("hidden-scroll", "main-navigation-open");

      for (var i = 0; i < inertEls.length; i++) {
        var inertEl = inertEls[i];

        ctx.visible
            ? inertEl.setAttribute("hidden", true)
            : inertEl.removeAttribute("hidden");
      }
    });

    this.$eventHub.on("expandNested", function (eventParams) {
      let active = eventParams && eventParams.uuid;
      ctx.currentActive = ctx.currentActive !== active ? active : null;

      this.isNavigationOpen = ctx.currentActive === active && this.isNavigationOpen && !this.isMobile ? this.isNavigationOpen : !this.isNavigationOpen;
      this.isNavigationOpen ? document.body.style.overflowY = "hidden" : document.body.style.overflowY = "scroll";
      this.$emit("header:isNavigationOpen", this.isNavigationOpen);
    });
  },

  mounted() {
    window.addEventListener("resize", this.overlayPositioning);
    window && window.removeEventListener("scroll", this.overlayPositioning);
    this.$eventHub.on("showContacts", (showContacts) => {
      if(showContacts) {
        this.currentActive = false;
      }
    });
  },

  destroyed() {
    document.removeEventListener("keyup", this.close);
  },
};
</script>
<style scoped>
.navigation-v2 {
 height: 600px;
}
.margin-edit-mode {
  margin-top: 500px;
}
</style>
